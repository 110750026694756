"use client";

import { FormEvent, ReactNode, useCallback, useState } from "react";
import { useRouter } from "next/navigation";
import {
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signInWithPopup,
    updateProfile,
    UserCredential,
} from "firebase/auth";
import { auth, facebookProvider, googleProvider } from "@/lib/firebase";
import {
    firebaseAuthMessagesTranslate,
    translateProviderName,
} from "../../utils";
import LogRocket from "logrocket";
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "@/components/ui/card";
import SocialButtons from "./socialButtons";
import { Label } from "@/components/ui/label";
import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { AuthAction, AuthCardProps, AuthSocialProvider } from "../../types";
import { LoaderCircle } from "lucide-react";
import { useSyncUrlWithAuthActions } from "../../hooks/useSyncUrlWithAuthAction";
import { client } from "@/lib/hono";
import TextWithLinks from "./textWithLinks";
import { cn } from "@/lib/utils";
import { T } from "@tolgee/react";

type Message = {
    message: string;
    keyName: string;
} | null;

export default function AuthCard({
    initialAction,
    socialProviders,
    textsWithLinks,
    isPlatform,
    tenantId,
    syncUrlWithAuthActions,
    noBorder,
    successCallback,
}: AuthCardProps) {
    const [errorMessage, setErrorMessage] = useState<Message>(null);
    const [successMessage, setSuccessMessage] = useState<Message>(null);
    const [loading, setLoading] = useState(false);
    const [action, setAction] = useState<AuthAction>(initialAction);
    const router = useRouter();

    useSyncUrlWithAuthActions(!!syncUrlWithAuthActions, action);

    textsWithLinks["login"] = {
        text: "שכחת סיסמה?",
        keyName: "auth-card.forgot-password",
        links: [
            {
                phrase: "שכחת סיסמה?",
                type: "button",
                onClick: () => setAction("reset-password"),
            },
        ],
    };

    async function handleSubmit({
        socialProvider,
        e,
    }: {
        socialProvider?: AuthSocialProvider;
        e?: FormEvent<HTMLFormElement>;
    }) {
        e?.preventDefault?.();
        setErrorMessage(null);
        setSuccessMessage(null);
        setLoading(true);

        const fullName = e?.currentTarget?.fullName?.value as string;
        const email = e?.currentTarget?.email?.value as string;
        const password = e?.currentTarget?.password?.value as string;

        auth.languageCode = "il"; // maybe it will make problems and translate error codes too
        auth.tenantId = tenantId || null;

        let authPromise;
        if (socialProvider === "google") {
            authPromise = signInWithPopup(auth, googleProvider);
        } else if (socialProvider === "facebook") {
            authPromise = signInWithPopup(auth, facebookProvider);
        } else if (action === "login") {
            authPromise = signInWithEmailAndPassword(auth, email, password);
        } else if (action === "signup") {
            authPromise = createUserWithEmailAndPassword(
                auth,
                email,
                password
            ).then(async (userCredential: UserCredential) => {
                await updateProfile(userCredential.user, {
                    displayName: fullName,
                });
                return userCredential;
            });
        } else if (action === "reset-password") {
            await sendPasswordResetEmail(auth, email)
                .then(() => {
                    setSuccessMessage({
                        keyName: "firebase-success.reset-password-link",
                        message: "מייל איפוס סיסמה נשלח לכתובת שהוזנה.",
                    });
                })
                .catch((error) => {
                    setErrorMessage({
                        keyName: `firebase-error.${error.code}`,
                        message:
                            firebaseAuthMessagesTranslate[error.code] ||
                            "אירעה שגיאה לא צפויה.",
                    });
                })
                .finally(() => {
                    setLoading(false);
                });
            return;
        } else {
            console.log(
                `Unknown social provider or action: ${socialProvider}, ${action}.`
            );
            setLoading(false);
            return;
        }

        try {
            const userCredential = await authPromise;

            await fetch("/api/login", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${await userCredential.user.getIdToken(
                        true
                    )}`,
                },
            });

            const res = await client.api.auth["create-user"].$post({
                json: {
                    uid: userCredential.user.uid,
                    email: userCredential.user.email,
                    displayName: userCredential.user.displayName || fullName,
                    phoneNumber: userCredential.user.phoneNumber,
                    authProvider: socialProvider || "email",
                    isPlatform: isPlatform,
                    tenantId: tenantId,
                },
            });
            const data = await res.json();
            console.log(data);

            if (!res.ok) {
                await fetch("/api/logout");
                throw new Error("Failed to create user in DB");
            }

            // force token refresh for propogating the new custom claims
            await fetch("/api/login", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${await userCredential.user.getIdToken(
                        true
                    )}`,
                },
            });

            successCallback?.(userCredential.user.uid);
        } catch (error: any) {
            console.log(error);

            if (error.code !== "auth/popup-closed-by-user") {
                setErrorMessage({
                    keyName: `firebase-error.${error.code}`,
                    message:
                        firebaseAuthMessagesTranslate[error.code] ||
                        "אירעה שגיאה לא צפויה.",
                });
            }

            if (!firebaseAuthMessagesTranslate[error.code]) {
                // it means that this is an unusual error.
                LogRocket.captureException(error, {
                    extra: {
                        socialProvider: socialProvider || "none",
                        action,
                        hint: "Unusual auth error.",
                    },
                });
            }

            setLoading(false);
        }
    }

    return (
        <Card
            className={cn(
                "w-full h-full p-4 md:p-6 lg:p-8",
                noBorder && "border-none shadow-none"
            )}
        >
            <AuthCardHeader
                action={action}
                socialProviders={socialProviders}
                errorMessage={errorMessage}
                successMessage={successMessage}
            />

            <CardContent className="space-y-6 px-0 pb-0 pt-6">
                {socialProviders.length > 0 && (
                    <>
                        <SocialButtons
                            providers={socialProviders}
                            handleClick={(socialProvider: AuthSocialProvider) =>
                                handleSubmit({ socialProvider })
                            }
                            loading={loading}
                        />

                        <AuthSeparator />
                    </>
                )}
                <form
                    onSubmit={(e) => handleSubmit({ e })}
                    className="flex flex-col gap-y-6"
                >
                    <AuthCardInputs action={action} loading={loading} />

                    {textsWithLinks[action] && (
                        <TextWithLinks {...textsWithLinks[action]} />
                    )}

                    <AuthCardSubmitButton action={action} loading={loading} />
                </form>
            </CardContent>

            <AuthCardNavFooter action={action} setAction={setAction} />
        </Card>
    );
}

const AuthCardSubmitButton = ({
    action,
    loading,
}: {
    action: AuthAction;
    loading: boolean;
}) => {
    let text = "";
    let keyName = "";

    switch (action) {
        case "login":
            text = "כניסה";
            keyName = "auth-card.submit.login";
            break;
        case "signup":
            text = "הרשמה";
            keyName = "auth-card.submit.signup";
            break;
        case "reset-password":
            text = "שליחה";
            keyName = "auth-card.submit.reset-password";
            break;
    }

    return (
        <Button type="submit" size="sm" className="w-full" disabled={loading}>
            {loading ? (
                <LoaderCircle className="size-4 animate-spin" />
            ) : (
                <T keyName={keyName} defaultValue={text} />
            )}
        </Button>
    );
};

const AuthCardHeader = ({
    action,
    socialProviders,
    errorMessage,
    successMessage,
}: {
    action: AuthAction;
    socialProviders: AuthSocialProvider[];
    errorMessage: Message;
    successMessage: Message;
}) => {
    let title: string | ReactNode = "";
    let description: string | ReactNode = "";

    const socialProvidersString = socialProviders
        .map((provider) => translateProviderName(provider))
        .join("/");

    switch (action) {
        case "login":
            title = <T keyName="auth-card.login.title" defaultValue="כניסה" />;
            description = socialProviders.length ? (
                <T
                    keyName="auth-card.login.description.with-providers"
                    params={{
                        socialProviders: socialProvidersString,
                    }}
                    defaultValue="הזינו מייל וסיסמה או המשיכו עם {socialProviders}"
                />
            ) : (
                <T
                    keyName="auth-card.login.description.no-providers"
                    defaultValue="הזינו מייל וסיסמה"
                />
            );
            break;
        case "signup":
            title = <T keyName="auth-card.signup.title" defaultValue="הרשמה" />;
            description = socialProviders.length ? (
                <T
                    keyName="auth-card.signup.description.with-providers"
                    params={{
                        socialProviders: socialProvidersString,
                    }}
                    defaultValue="מלאו את הפרטים או המשיכו עם {socialProviders}"
                />
            ) : (
                <T
                    keyName="auth-card.signup.description.no-providers"
                    defaultValue="מלאו את הפרטים"
                />
            );
            break;
        case "reset-password":
            title = (
                <T
                    keyName="auth-card.reset-password.title"
                    defaultValue="איפוס סיסמה"
                />
            );
            description = socialProviders.length ? (
                <T
                    keyName="auth-card.reset-password.description.with-providers"
                    params={{
                        socialProviders: socialProvidersString,
                    }}
                    defaultValue="הזינו את כתובת המייל איתה נרשמתם לקבלת מייל איפוס סיסמה או המשיכו עם {socialProviders}"
                />
            ) : (
                <T
                    keyName="auth-card.reset-password.description.no-providers"
                    defaultValue="הזינו את כתובת המייל איתה נרשמתם לקבלת מייל איפוס סיסמה"
                />
            );
            break;
    }

    return (
        <CardHeader className="p-0 text-center">
            <CardTitle>{title}</CardTitle>
            <CardDescription>{description}</CardDescription>
            {errorMessage && (
                <p className="text-red-500 font-medium">
                    <T
                        keyName={errorMessage.keyName}
                        defaultValue={errorMessage.message}
                    />
                </p>
            )}
            {successMessage && (
                <p className="text-green-500 font-medium">
                    <T
                        keyName={successMessage.keyName}
                        defaultValue={successMessage.message}
                    />
                </p>
            )}
        </CardHeader>
    );
};

const AuthCardInputs = ({
    action,
    loading,
}: {
    action: AuthAction;
    loading: boolean;
}) => {
    return (
        <div className="flex flex-col gap-y-6">
            {action === "signup" && (
                <div className="flex flex-col gap-2">
                    <Label htmlFor="fullName">
                        <T
                            keyName="auth-card.inputs.full-name.label"
                            defaultValue="שם מלא"
                        />
                    </Label>
                    <Input
                        id="fullName"
                        name="fullName"
                        type="text"
                        disabled={loading}
                        required
                    />
                </div>
            )}
            <div className="flex flex-col gap-2">
                <Label htmlFor="email">
                    <T
                        keyName="auth-card.inputs.email.label"
                        defaultValue="מייל"
                    />
                </Label>
                <Input
                    id="email"
                    name="email"
                    type="email"
                    disabled={loading}
                    required
                />
            </div>
            {action !== "reset-password" && (
                <div className="flex flex-col gap-2">
                    <Label htmlFor="password">
                        <T
                            keyName="auth-card.inputs.password.label"
                            defaultValue="סיסמה"
                        />
                    </Label>
                    <Input
                        id="password"
                        name="password"
                        type="password"
                        disabled={loading}
                        required
                    />
                </div>
            )}
        </div>
    );
};

const AuthCardNavFooter = ({
    action,
    setAction,
}: {
    action: AuthAction;
    setAction: (action: AuthAction) => void;
}) => {
    let keyName = "";
    let defaultValue = "";
    let params = {};
    let linkChangeAction: AuthAction = "login";

    switch (action) {
        case "login":
            keyName = "auth-card.nav-footer.signup";
            defaultValue = "אין לך עדיין חשבון? <link>להרשמה</link>";
            params = {
                link: (content: React.ReactNode) => (
                    <button
                        onClick={() => setAction("signup")}
                        className="ms-0.5 font-medium text-gray-700 hover:underline"
                    >
                        {content}
                    </button>
                ),
            };
            linkChangeAction = "signup";
            break;
        case "signup":
            keyName = "auth-card.nav-footer.login";
            defaultValue = "יש לך כבר חשבון? <link>כניסה</link>";
            params = {
                link: (content: React.ReactNode) => (
                    <button
                        onClick={() => setAction("login")}
                        className="ms-0.5 font-medium text-gray-700 hover:underline"
                    >
                        {content}
                    </button>
                ),
            };
            linkChangeAction = "login";
            break;
        case "reset-password":
            keyName = "auth-card.nav-footer.reset-password";
            defaultValue = "<link>כניסה</link>";
            params = {
                link: (content: React.ReactNode) => (
                    <button
                        onClick={() => setAction("login")}
                        className="ms-0.5 font-medium text-gray-700 hover:underline"
                    >
                        {content}
                    </button>
                ),
            };
            linkChangeAction = "login";
            break;
    }

    return (
        <CardFooter className="pb-0 mt-8 text-sm text-gray-600 flex w-full justify-center">
            <T keyName={keyName} params={params} defaultValue={defaultValue} />
        </CardFooter>
    );
};

const AuthSeparator = () => {
    return (
        <div className="relative">
            <div className="absolute inset-0 flex items-center">
                <div className="w-full border-t border-gray-200" />
            </div>
            <div className="relative flex justify-center text-sm leading-6">
                <span className="bg-white px-6">
                    <T keyName="auth-card.auth-separator" defaultValue="או" />
                </span>
            </div>
        </div>
    );
};
