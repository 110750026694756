import { User } from "../auth/types";
import { Permission, PermissionKey } from "./types";

// Convert permissions array to enum values
export function permissionsToEnumValues(
    permissions: PermissionKey[]
): number[] {
    if (!permissions) return [];

    return permissions.map((permission) => Permission[permission]);
}

// Convert enum values back to permission keys
export function enumValuesToPermissions(enumValues: number[]): PermissionKey[] {
    if (!enumValues) return [];

    return enumValues.map((value) => {
        const permissionKey = Object.keys(Permission).find(
            (key) => Permission[key as PermissionKey] === value
        );
        return permissionKey as PermissionKey;
    });
}

// Check if a user has a specific permission
export function hasPermission(
    user: User,
    permissionToCheck: PermissionKey
): boolean {
    return !!user.permissions?.includes(Permission[permissionToCheck]);
}
