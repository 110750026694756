"use client";

import { AuthProvider } from "@/features/auth/hooks/useAuth";
import ModalsProvider from "./modalsProvider";
import { User } from "@/features/auth/types";

interface ProviderProps {
    children: React.ReactNode;
    user: User | null;
}

export const PlatformProviders = ({ children, user }: ProviderProps) => {
    return (
        <>
            <ModalsProvider modalsFor="platform" />

            <AuthProvider user={user}>{children}</AuthProvider>
        </>
    );
};
