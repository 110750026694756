"use client";

import { cn } from "@/lib/utils";
import { usePathname } from "next/navigation";
import { NavigationMultiItem } from "./sidebar";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { hasPermission } from "@/features/admin/utils";
import {
    Accordion,
    AccordionItem,
    AccordionTrigger,
    AccordionContent,
} from "@/components/ui/accordion";
import { useEffect, useState } from "react";
import { Badge } from "@/components/ui/badge";
import { T } from "@tolgee/react";

export default function SidebarMultiItem({
    item,
}: {
    item: NavigationMultiItem;
}) {
    const { user } = useAuth();
    const pathname = usePathname();
    const [mounted, setMounted] = useState(false);
    const [open, setOpen] = useState("");

    useEffect(() => {
        const hasActiveSubItem = item.children.some((subItem) =>
            pathname.includes(subItem.href)
        );
        setOpen(hasActiveSubItem ? item.label : "");
    }, [pathname, item.children, item.label]);

    useEffect(() => {
        setMounted(true);
    }, []);

    if (item.adminOnly && !user?.admin) {
        return null;
    }

    return (
        <Accordion
            type="single"
            collapsible
            value={open}
            onValueChange={(value) => setOpen(value)}
            className="w-full"
            dir="rtl"
        >
            <AccordionItem className="border-0" value={item.label}>
                <AccordionTrigger
                    className={cn(
                        !mounted && "transition-none",
                        item.adminOnly && "text-blue-600",
                        "hover:bg-muted flex flex-row-reverse items-center rounded-md p-2 text-sm"
                    )}
                >
                    <div className="flex flex-row gap-2">
                        <item.icon className="size-5" />
                        <T keyName={item.keyName} defaultValue={item.label} />
                        {item.comingSoon && (
                            <Badge variant="outline">
                                <T
                                    keyName="platform.sidebar.coming-soon-badge"
                                    defaultValue="בקרוב"
                                />
                            </Badge>
                        )}
                    </div>
                </AccordionTrigger>
                <AccordionContent>
                    <ul className="w-full mt-1">
                        {item.children.map((subItem) => {
                            if (
                                !user ||
                                (subItem.requiredPermission &&
                                    !hasPermission(
                                        user,
                                        subItem.requiredPermission
                                    ))
                            ) {
                                return null;
                            }
                            return (
                                <a
                                    key={subItem.label}
                                    href={
                                        subItem.comingSoon ? "#" : subItem.href
                                    }
                                    onClick={
                                        subItem.comingSoon
                                            ? (e) => e.preventDefault()
                                            : undefined
                                    }
                                    className={cn(
                                        pathname.includes(subItem.href)
                                            ? "bg-muted text-muted-foreground"
                                            : "hover:bg-muted text-muted-foreground",
                                        subItem.requiredPermission &&
                                            "text-blue-600",
                                        "block rounded-md p-2 text-sm"
                                    )}
                                >
                                    {/* <T
                                        keyName={subItem.keyName}
                                        defaultValue={subItem.label}
                                    /> */}
                                    {subItem.label}
                                    {subItem.comingSoon && (
                                        <Badge
                                            variant="outline"
                                            className="ms-2"
                                        >
                                            {/* <T
                                                keyName="platform.sidebar.coming-soon-badge"
                                                defaultValue="בקרוב"
                                            /> */}
                                            בקרוב
                                        </Badge>
                                    )}
                                </a>
                            );
                        })}
                    </ul>
                </AccordionContent>
            </AccordionItem>
        </Accordion>
    );
}
