import { AuthSocialProvider } from "./types";

export const translateProviderName = (provider: AuthSocialProvider) => {
    if (provider === "google") return "גוגל";
    else if (provider === "facebook") return "פייסבוק";
    else return "Unknown";
};

export const firebaseAuthMessagesTranslate: { [key: string]: string } = {
    "auth/invalid-credential": "המייל או הסיסמה שהוזנו שגויים",
    "auth/invalid-login-credentials": "המייל או הסיסמה שהוזנו שגויים",
    "auth/user-not-found": "לא נמצא משתמש עם אימייל זה",
    "auth/wrong-password": "סיסמה שגויה",
    "auth/account-exists-with-different-credential":
        "שגיאה: המשתמש נרשם בדרך אחרת",
    "auth/popup-closed-by-user": "",
    "auth/invalid-email": "כתובת המייל שהוזנה לא תקינה",
    "auth/email-already-in-use": "האימייל שבחרת כבר נמצא בשימוש בחשבון אחר",
    "auth/weak-password": "הסיסמה שבחרת חלשה מידי",
    "user-already-exists": "המשתמש קיים",
    "auth/unauthorized-domain": "שגיאה: הדומיין לא מאומת",
    "auth/operation-not-allowed": "שגיאה: הספק לא אושר, אנא נסו ספק אחר",
};

export const domainToTenantIdMapping: { [key: string]: string } = {
    "yoav.localhost": "yoav-tenant-id",
};

export type TenantId = string | undefined;

export const getTenantIdFromDomain = (domain: string): TenantId => {
    if (!(domain in domainToTenantIdMapping)) {
        return "not-found";
    }

    return domainToTenantIdMapping[domain];
};
