"use client";

import { Button } from "@/components/ui/button";
import { AuthSocialProvider } from "../../types";
import { FcGoogle } from "react-icons/fc";
import { FaFacebook } from "react-icons/fa6";
import { translateProviderName } from "../../utils";
import { T } from "@tolgee/react";

interface SocialButtonsProps {
    providers: AuthSocialProvider[];
    handleClick: (socialProvider: AuthSocialProvider) => void;
    loading: boolean;
}

export default function SocialButtons({
    providers,
    handleClick,
    loading,
}: SocialButtonsProps) {
    const getProviderButtonText = (provider: AuthSocialProvider) => {
        if (providers.length > 1) {
            return translateProviderName(provider);
        } else {
            return (
                <T
                    keyName="auth-card.social-button.single-provider"
                    params={{ provider: translateProviderName(provider) }}
                    defaultValue="המשיכו עם {provider}"
                />
            );
        }
    };

    return (
        <div className="flex flex-row w-full gap-3">
            {providers.includes("google") && (
                <Button
                    onClick={() => handleClick("google")}
                    variant="outline"
                    size="default"
                    className="w-full relative"
                    disabled={loading}
                >
                    <FcGoogle className="size-5 me-2" />
                    {getProviderButtonText("google")}
                </Button>
            )}
            {providers.includes("facebook") && (
                <Button
                    onClick={() => handleClick("facebook")}
                    variant="outline"
                    size="default"
                    className="w-full relative"
                    disabled={loading}
                >
                    <FaFacebook className="size-5 me-2 text-[#1877F2]" />
                    {getProviderButtonText("facebook")}
                </Button>
            )}
        </div>
    );
}
