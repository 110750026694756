import { useEffect } from "react";
import { AuthAction } from "../types";

export const useSyncUrlWithAuthActions = (
    syncUrlWithAuthActions: boolean,
    action: AuthAction
) => {
    useEffect(() => {
        if (syncUrlWithAuthActions) {
            // on platform auth update the url too when changing auth actions

            // Update the URL in the browser without causing a page reload
            const searchParams = window.location.search;
            const newPath = `/auth/${action}${searchParams}`; // keep searchParams

            if (window.location.pathname !== newPath) {
                window.history.pushState({}, "", newPath);
            }
        }
    }, [action, syncUrlWithAuthActions]);
};
