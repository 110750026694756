"use client";

import { createContext, useContext } from "react";
import { User } from "../types";

export interface AuthContextValue {
    user: User | null;
}

export const AuthContext = createContext<AuthContextValue>({
    user: null,
});

// after you wrap the layout you can use this hook to get the user
export const useAuth = () => useContext(AuthContext);

export interface AuthProviderProps {
    user: User | null;
    children: React.ReactNode;
}

export const AuthProvider: React.FunctionComponent<AuthProviderProps> = ({
    user,
    children,
}) => {
    return (
        <AuthContext.Provider
            value={{
                user,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};
