"use client";

import { cn } from "@/lib/utils";
import { NavigationSingleItem } from "./sidebar";
import { usePathname } from "next/navigation";
import { Badge } from "@/components/ui/badge";
import { useAuth } from "@/features/auth/hooks/useAuth";
import { hasPermission } from "@/features/admin/utils";
import { T } from "@tolgee/react";

export default function SidebarSingleItem({
    item,
}: {
    item: NavigationSingleItem;
}) {
    const pathname = usePathname();
    const { user } = useAuth();

    const current = pathname === item.href || pathname.includes(item.href);

    if (
        !user ||
        (item.requiredPermission &&
            !hasPermission(user, item.requiredPermission))
    ) {
        return null;
    }

    return (
        <a
            href={item.comingSoon ? "#" : item.href}
            onClick={item.comingSoon ? (e) => e.preventDefault() : undefined}
            className={cn(
                current ? "bg-muted" : "hover:bg-muted",
                item.requiredPermission && "text-blue-600",
                "flex flex-row items-center gap-2 rounded-md p-2 text-sm font-medium"
            )}
        >
            <item.icon className="size-5" />
            {item.label}
            {/* <T keyName={item.keyName} defaultValue={item.label} /> */}
            {item.comingSoon && (
                <Badge variant="outline">
                    {/* <T
                        keyName="platform.sidebar.coming-soon-badge"
                        defaultValue="בקרוב"
                    /> */}
                    בקרוב
                </Badge>
            )}
        </a>
    );
}
